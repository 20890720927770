import React, { useCallback, useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { analytics } from "@welldigital/ui-common";
import {
  Alert,
  Form,
  IRadioOption,
  RadioSelectField,
  Typography,
} from "@welldigital/components";
import events from "../../events";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import { Paths } from "../paths";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import {
  BookingTypes,
  Appointment,
} from "../../stores/appointment/appointment.types";
import { bookingForValidationMessages } from "../../components/validationMessages";
import { checkIfServiceIsDisabled } from "../BookAppointmentPage/BookAppointmentPage.utils";
import { ServiceIds } from "../../stores/service/service.constants";

enum BookingForPageRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
}

type BookingForPageProps = Pick<
  Appointment,
  keyof typeof BookingForPageRequiredProps
>;

export const BookingForPage: React.FC<BookingForPageProps> = ({
  service,
  pharmacy,
}) => {
  const { setBookingType } = useAppointmentStore();
  const history = useHistory();
  const isServiceDisabled = useMemo(
    () =>
      checkIfServiceIsDisabled({
        isFlu: service.id === ServiceIds.Flu,
        locationId: pharmacy.id,
      }),
    [pharmacy.id, service.id]
  );

  const bookingOptions: IRadioOption[] = useMemo(
    () => [
      { label: "Myself", id: BookingTypes.owner },
      {
        label: "Myself and other people",
        id: BookingTypes.otherIncludingOwner,
      },
      {
        label: "Other people, not including myself",
        id: BookingTypes.otherExcludingOwner,
      },
    ],
    []
  );

  const submitNext = useCallback(
    ({ bookingType }: { bookingType: BookingTypes }) => {
      if (!bookingType) return;

      setBookingType(bookingType);

      analytics.trackEvent({
        flow: service.analyticsName,
        event: events.bookingFor.next,
        metadata: {
          person: events.bookingFor.bookingTypeMap[bookingType],
        },
      });

      switch (bookingType) {
        case BookingTypes.owner: {
          return history.push(
            generatePath(Paths.CustomerDetails, { customerId: "owner" })
          );
        }
        case BookingTypes.otherIncludingOwner:
        case BookingTypes.otherExcludingOwner: {
          return history.push(Paths.BookingDetails);
        }
      }
    },
    [service, setBookingType, history]
  );

  if (isServiceDisabled) {
    return (
      <MainLayout>
        <Typography variant={"h4"} spacingAfter={4}>
          Flu vaccination in our community pharmacies
        </Typography>
        <Alert
          type={"info"}
          message={
            <>
              <Typography variant={"body2"} spacingAfter={1}>
              Appointment booking for this current flu season is now closed. Please contact your local Well Pharmacy to check vaccine and pharmacist availability. 
              </Typography>
              <Typography variant={"body2"} spacingAfter={1}>
              Flu appointment booking for Autumn 2025 will open in Summer 2025, please check back later. 
              </Typography>
            </>
          }
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Form onSubmit={submitNext}>
        <Typography variant={"h4"}>Who is the booking for?</Typography>
        <Typography paragraph color={"textSecondary"}>
          You can only book appointments for other people over the age of 18 if
          you have their consent to book on their behalf. Under 18s must be
          accompanied by a legal guardian.
        </Typography>

        <RadioSelectField
          name={"bookingType"}
          options={bookingOptions}
          fullWidth
          variant={"contained"}
          required
          validationMessages={bookingForValidationMessages}
        />

        <LayoutNavigation
          backButton={{
            onClick: () => history.push(Paths.Locations),
          }}
          nextButton={{
            type: "submit",
            children: "Next",
          }}
        />
      </Form>
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<BookingForPageProps>
    validatedProps={
      Object.keys(BookingForPageRequiredProps) as (keyof Appointment)[]
    }
    page={BookingForPage}
  />
);
